export enum InternalSettingTypes {
  AdminPermissions = "AdminPermissions",
  OnboardingArchived = "OnboardingArchived",
  OnboardingContacted = "OnboardingContacted"
}

export enum InternalSettingGroup {
  PERMISSIONS = 0,
  ONBOARDING = 2
}

export enum AdminPermission {
  USERS = 'USERS',
  USERS_CHANGE_SETTINGS = 'USERS_CHANGE_SETTINGS',
  ACTIVITY = 'ACTIVITY',
  MAILER = 'MAILER',
  COURSES = 'COURSES',
  PROVIDERS = 'PROVIDERS',
  CLIENTS = 'CLIENTS',
  FINANCE = 'FINANCE',
  ONBOARDING = 'ONBOARDING',
  REVIEWS = 'REVIEWS',
}

export enum UserSettingTypes {
  SignupData = 'SignupData'
}

export enum UserSettingGroup {
  EMAIL = 'EMAIL',
  FUTURE = 'FUTURE',
  SIGNUP = 'SIGNUP',
  PAYMENTS = 'PAYMENTS',
  DOMAIN = 'DOMAIN'
}
// wat: https://stackoverflow.com/questions/50376977/generic-type-to-get-enum-keys-as-union-string-in-typescript
// export function EnumEnum<TEnum>(e: TEnum): Array<[keyof typeof e, typeof e]> {
//   return Object.keys(e)
//     .filter((k: any) => !isNaN(Number(e[(k as keyof typeof e)])))
//     .map((k: string) => {
//       const e_v = e[(k as keyof typeof e)] as unknown as typeof e
//       return [k as keyof typeof e, e_v]
//     })
// }