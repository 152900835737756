import React from 'react'
import { User } from 'src/types'

import { Dialog, DialogTitle, DialogContent, Button, DialogActions } from '@material-ui/core';
import { UserSettingTypes } from 'src/enums';
import JSONToDiv from 'src/components/signup_data';


interface SignupDataDialog {
  user: User,
  onClose: any,
}

export default function SignupDataDialog(props: SignupDataDialog) {
  const { user, onClose } = props
  const signup_data = user.getUserSettingValue(UserSettingTypes.SignupData)

  return <Dialog open={!!user} onClose={onClose} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">Signup Data: {user.info.name} {`<${user.email}>`}</DialogTitle>
    <DialogContent>
      <JSONToDiv json={signup_data} />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Cancel
    </Button>
    </DialogActions>

  </Dialog>
}