import React, { useState, useEffect } from 'react'
import { OrderedMap } from 'immutable';
import fetchP from 'src/fetchP';
import MaterialTable from 'material-table';
import tableIcons from 'src/table_icons';
import { Box, Grid, Switch } from '@material-ui/core';
import moment from 'moment';
import onboardStyles from './styles';
import { SignupUser } from './signup_user';
import { ActionsGridItem } from './actions_grid_item';
import { MsgBox } from './msgbox';
import JSONToDiv from 'src/components/signup_data';

class ClientSignupData {
  org = ""
  brief = ""
  serv: Array<String> = []
  details = ""
  urgent = false
  email = ""
  name = ""
  phone = ""
  referral = ""
  signup_type = ""
}

export class ClientUserWithSignupData extends SignupUser({
  signup_data: new ClientSignupData()
}) { }

export default function ClientSignups() {
  const [loading, setLoading] = useState(true)
  const [users, setUsers] = useState<OrderedMap<string, ClientUserWithSignupData>>(OrderedMap<string, ClientUserWithSignupData>())
  const [showArchived, setShowArchived] = useState(false)
  const classes = onboardStyles();

  useEffect(() => {
    setLoading(true)
    fetchP({
      url: '/api/onboarding/signups/HIRE',
      success: (json: any) => {
        setUsers(OrderedMap(json.users.map((u: any) => [u['email'], new ClientUserWithSignupData(u)])))
        setLoading(false)
      }
    })
  }, [])

  if (loading) {
    return <h1>Loading client signups...</h1>
  }

  function updateUser(u: any) {
    setUsers(users.set(u['email'], new ClientUserWithSignupData(u)))
  }

  function DetailPanel(user: ClientUserWithSignupData) {
    if (user.active) return <h1>User is active!</h1>

    return <Box className={classes.paper}>
      <Grid container
        direction="row"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item sm>
          <Grid container direction="column" justify="space-between" className={classes.maxHeight}>
            <Grid item>
              <JSONToDiv json={user.signup_data} />
            </Grid>
            <ActionsGridItem user={user} updateUser={updateUser} />
          </Grid>
        </Grid>
        <Grid item sm>
          <MsgBox user={user} />
        </Grid>
      </Grid>
    </Box>
  }


  return <>
    <MaterialTable
      title="Client Signups"
      data={users.filter(u => showArchived ? u.archived : !u.archived).valueSeq().toArray()}
      columns={[
        { title: 'Date', field: 'created_date', type: 'datetime', render: row => moment(row.created_date).fromNow() },
        { title: "Name", field: ("info.name" as any) },
        { title: 'Contacted', field: 'contacted', type: 'boolean' },
        { title: "Email", field: 'email', render: row => <a href={`mailto:${row.email}`}>{row.email}</a> },
        { title: "Tel", field: 'signup_data.phone' as any, render: row => <a href={`tel:${row.signup_data.phone}`}>{row.signup_data.phone}</a> },
        { title: "Org", field: 'signup_data.org' as any },
        { title: "Req", field: 'serv_str' },
      ]}
      icons={tableIcons}
      detailPanel={DetailPanel}
      actions={[
        {
          icon: () => <>Show Archived:<Switch checked={showArchived} /></>,
          tooltip: 'Show Archived',
          isFreeAction: true,
          onClick: () => setShowArchived(!showArchived)
        }
      ]}
    />
  </>
}