import React, { useRef, useContext } from 'react'
import MaterialTable from 'material-table'
import tableIcons from 'src/table_icons'
import fetchP from 'src/fetchP'
import Activity from 'src/types/activity';
import { UserContext } from 'src/UserContext';
import { AdminPermission } from 'src/enums';


interface IProps {
  parent: string
}

export default function ActivityTable({ parent }: IProps) {
  const current_user = useContext(UserContext)
  const cursor = useRef(``)
  const count = useRef(0)

  const tableRef = useRef();
  const currentParent = useRef(parent)

  if (!current_user.hasAdminPermission(AdminPermission.ACTIVITY)) {
    return <h1>No access to Activity</h1>
  }

  if (parent !== currentParent.current) {
    currentParent.current = parent
    tableRef.current && (tableRef.current as any).onQueryChange()
  }

  return <MaterialTable
    title="Activity"
    tableRef={tableRef}
    icons={tableIcons}
    columns={[
      { field: 'time_disp', type: 'datetime', grouping: false, filtering: false },
      { field: 'keychain_disp', render: row => row.keychain_disp },
      { field: 'disp' }
    ]}
    data={query => new Promise((resolve) => {
      var url: string
      if (parent) {
        url = `/api/activity/activity/${parent}`
      } else {
        url = '/api/activity/activity'
      }

      if (query.page) {
        url += `/p/${query.page}/c/${cursor.current}`
      }

      fetchP({
        url,
        success: (json: any) => {
          const acts = json.activity.map((a: any) => new Activity(a))
          if (!query.page) {
            count.current = json.count
            cursor.current = json.cursor
          }
          resolve({
            data: acts,
            page: query.page,
            totalCount: count.current
          })
        }
      })
    })}

    options={{
      pageSize: 20,
      pageSizeOptions: [20],
      search: false,
      header: false
    }}
  />
}