import React, { useState, useEffect, useRef } from 'react'

import { IUser } from 'src/types/user';
import { Grid, makeStyles, Theme, createStyles, FormControl, InputLabel, Input, InputAdornment, IconButton, CircularProgress, Tooltip } from '@material-ui/core';
import fetchP from 'src/fetchP';

import clsx from 'clsx'
import moment from 'moment';

import { Send } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';
import { Record, OrderedSet } from 'immutable';

interface IProps {
  user: IUser
}

class Msg extends Record({
  key: '',
  user_displayname: '',
  log: '',
  created: ''
}) {

  get created_date() {
    return Date.parse(this.created)
  }
}

export function MsgBox(props: IProps) {
  const [loading, setLoading] = useState(true)
  const [msgs, setMsgs] = useState<OrderedSet<Msg>>(OrderedSet())
  const [new_msg, setNewMsg] = useState('')
  const classes = useStyles();
  const msg_ref = useRef();

  const { user } = props;


  function gotMsgs(json: any) {
    setMsgs(OrderedSet(json['msgs'].map((m: any) => new Msg(m))))
    setLoading(false)
  }

  useEffect(() => {
    setLoading(true)
    fetchP({
      url: `/api/onboarding/msgs/${user.key}`,
      success: gotMsgs
    })
  }, [user.key])



  useEffect(() => {
    if (msg_ref.current) (msg_ref.current as any).scrollIntoView({ behavior: "smooth" })
  }, [msgs]);

  function onSubmit(e: React.FormEvent) {
    e.preventDefault()
    if (!new_msg.trim()) {
      return alert("Stop cluttering the database, enter an actual note!")
    }

    fetchP({
      url: '/api/onboarding/add_note',
      method: 'POST',
      body_obj: {
        user_key: user.key,
        msg: new_msg,
      },
      success: (json: any) => {
        if (!json['success']) {
          alert("Adding not failed!")
        } else {
          const msg = new Msg(json['msg'])
          setMsgs(msgs.add(msg))
          setNewMsg('')
          setLoading(false)
        }
      }
    })
    setLoading(true)
  }

  return <Grid
    container
    direction="column"
    justify="space-between"
    alignItems="stretch"
    className={classes.msgBox}
    spacing={1}

  >
    <Grid xs item className={clsx(classes.msgBoxRow, classes.msgListBox)}>
      {loading && 'Loading...'}
      {msgs.sort((a, b) => (a.created_date - b.created_date)).map(msg => {
        const created_moment = moment(msg.created_date)
        return <p key={msg.key}>
          <span className={classes.msg_author}>{msg.user_displayname} </span>
          <Tooltip title={created_moment.format('lll')} placement="top">
            <span className={classes.msg_date}>{created_moment.fromNow()}</span>
          </Tooltip>
          <span className={classes.msg_log}>: {msg.log}</span>
        </p>
      })
      }
      <div style={{ float: "left", clear: "both" }}
        ref={msg_ref as any}>
      </div>

    </Grid>
    <Grid xs={1} item className={clsx(classes.msgBoxRow, classes.maxWidth)}>
      <form onSubmit={onSubmit}>
        <FormControl className={classes.maxWidth}>
          <InputLabel htmlFor="adornment-new_msg">Notes</InputLabel>
          <Input
            id="adornment-new_msg"
            disabled={loading}
            type='text'
            value={new_msg}
            onChange={e => setNewMsg(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  disabled={loading}
                  aria-label="toggle password visibility"
                  onClick={onSubmit}
                >
                  <Send />
                  {loading && <CircularProgress size={48} className={classes.fabProgress} />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </form>

    </Grid>
  </Grid>
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    msgBox: {
      border: '1px grey solid',
      padding: theme.spacing(1),
      minHeight: '350px',
      height: '100%'
    },
    msgBoxRow: {
      maxWidth: 'unset'
    },
    msgListBox: {
      maxHeight: '500px',
      overflowY: 'scroll'
    },
    maxWidth: {
      width: '100%'
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    fabProgress: {
      color: green[500],
      position: 'absolute',
      zIndex: 1,
    },
    msg_author: {
      color: theme.palette.text.primary
    },
    msg_date: {
      color: theme.palette.text.secondary
    },
    msg_log: {
      color: theme.palette.text.primary
    }
  }),
);