import React from 'react'
import { useLocation } from "react-router-dom";
import ActivityTable from 'src/components/activity/table';


export default function Activities({ fixed_parent = '' }) {
  const location = useLocation();
  const spl = location.pathname.split('/')

  const parent = fixed_parent ? fixed_parent : spl.length === 3 ? spl[2] : ''

  return <ActivityTable parent={parent} />
}