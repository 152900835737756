import React, { useState, useImperativeHandle, forwardRef, useRef, useEffect } from 'react'

import {
  Dialog, DialogContent,
} from '@material-ui/core';
import fetchP from 'src/fetchP';
import JSONToDiv from 'src/components/signup_data';


export const ProviderPaymentInfoModal = forwardRef((props: any, ref: any) => {
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState({})

  const providerRow = useRef()
  useImperativeHandle(ref, () => ({
    show: (_row: any) => {
      providerRow.current = _row.key
      setVisible(true)
    }
  }))

  useEffect(() => {
    if (!visible) return;
    fetchP({
      url: `/api/providers/payment_info/${providerRow.current}`,
      success: (json: any) => {
        setData(json['payment_info'])
      }
    })

  }, [visible, providerRow])

  if (!providerRow.current) return <></>

  return <Dialog open={visible} onClose={() => setVisible(false)} fullWidth={true} maxWidth={false}>
    <DialogContent>
      <JSONToDiv json={data as any['payment_info']} />
    </DialogContent>
  </Dialog >
})