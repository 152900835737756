import { UserBase } from "src/types/user";
import { InternalSettingTypes } from "src/enums";

export const SignupUser = (extendedValues: Object) => class extends UserBase(extendedValues) {
  get serv_str() {
    return this.signup_data.serv.join(', ')
  }

  get contacted() {
    return this.getInternalSettingValue(InternalSettingTypes.OnboardingContacted)
  }

  get archived() {
    return this.getInternalSettingValue(InternalSettingTypes.OnboardingArchived)
  }
}