import { Record } from "immutable";
import { LangPairRate, LangPair, LangRate } from "./lang";
import { AssgStats } from './stats'

export enum ContactType {
  EMAIL = 0,
  MOBILE = 1
}

export enum ProfileType {
  BASE = 0,
  TRANSLATOR = 1,
  TRANSCRIBER = 2,
  WRITER = 3
}

export interface IContact {
  type: ContactType,
  value: string
}



interface IProvider {
  key: string,
  info: { name: string, contacts: Array<IContact> }
  description: string,
  available: boolean,
  negotiable: boolean,
  has_gst: boolean,
  lang_pair_rates: Array<LangPairRate>,
  lang_rates: Array<LangRate>,
  profile_stats: AssgStats,
  user_stats: AssgStats,
  profile_type: ProfileType,
  lp_col: LangPair //We're doing this shenanigans for the provider page, so we can group by langpair
}

const defaultProviderProps: IProvider = {
  key: '',
  info: { name: ``, contacts: [] },
  description: ``,
  available: false,
  negotiable: false,
  has_gst: false,
  lang_pair_rates: [],
  lang_rates: [],
  profile_stats: new AssgStats(),
  user_stats: new AssgStats(),
  profile_type: ProfileType.BASE,
  lp_col: new LangPair({ src: '', trg: '' })
}

export class Provider extends Record(defaultProviderProps) implements IProvider {
  constructor(jsObj: any) {
    super({
      ...jsObj,
      profile_stats: new AssgStats(jsObj.profile_stats),
      user_stats: new AssgStats(jsObj.user_stats),
      lang_pair_rates: jsObj.lang_pair_rates ? jsObj.lang_pair_rates.map((lpr: any) => new LangPairRate(lpr)) : [],
      lang_rates: jsObj.lang_rates ? jsObj.lang_rates.map((lr: any) => new LangRate(lr)) : []
    })
  }

  get lang_str() {
    return this.lp_col.toString()
  }
}