import { Record } from 'immutable'

import { LangPair, Lang, LANG_MAP } from './lang'
import { ProfileType } from './provider'


enum ContactType {
  EMAIL = 0,
  MOBILE = 1
}

class Contact {
  contact_type = ContactType
  value = ``
}

enum ProjectRole {
  OWNER = 0,
  MEMBER = 1,
  IN_CONTRACT = 9
}

class TeamMember {
  created = ``
  name = ``
  user_key = ``
  role = ProjectRole.MEMBER
}

class Requirement {
  lang_pair?: LangPair
  src?: Lang
  profile_type: ProfileType

  constructor(jsObj: any) {
    if (jsObj.lang_pair) {
      this.lang_pair = new LangPair(jsObj.lang_pair)
    }
    if (jsObj.src) {
      this.src = LANG_MAP[jsObj.src]
    }

    this.profile_type = jsObj.profile_type
  }

  get key() {
    if (this.lang_pair) return `${this.profile_type}-${this.lang_pair.key}`
    if (this.src) return `${this.profile_type}-${this.src.key}`
    // debugger
    throw Error('No lang_pair or lang')
  }

  toString() {
    if (this.lang_pair) return this.lang_pair.toString()
    if (this.src) return this.src.toString()
    // debugger
    throw Error('No lang_pair or lang')
  }
}

export class Project extends Record({
  key: '',
  title: '',
  org: '',
  created: '',
  owner: '',
  brief: '',
  locked: false,
  billing: { enabled: false, required: false, comission_rate: Number.NaN, offline_billing: undefined },
  has_contracts: false,
  has_assignments: false,
  has_invoices: false,
  contacts: Array<Contact>(),
  team: Array<TeamMember>(),
  reqs: Array<Requirement>()
}) {
  get created_date() {
    return Date.parse(this.created as any)
  }

  constructor(jsObj: any) {
    super({
      ...jsObj,
      reqs: jsObj.reqs ? jsObj.reqs.map((r: any) => new Requirement(r)) : []
    })
  }

  get owner() {
    const o = this.team.find(tm => tm.role === ProjectRole.OWNER)
    return o ? o.name : 'No owner!'
  }

}
