import React, { useState, useEffect } from 'react'
import { OrderedMap } from 'immutable';
import fetchP from 'src/fetchP';
import MaterialTable from 'material-table';
import tableIcons from 'src/table_icons';
import { Box, Grid, Switch } from '@material-ui/core';

import moment from 'moment';
import onboardStyles from './styles';
import { MsgBox } from './msgbox';
import { ActionsGridItem } from './actions_grid_item'
import { SignupUser } from './signup_user';
import JSONToDiv from 'src/components/signup_data';

class ProviderSignupData {
  bio = ""
  serv: Array<String> = []
  serv_details: any = {}
  languages = ""
  email = ""
  name = ""
  phone = ""
  referral = ""
  signup_type = ""
}

export class ProviderUserWithSignupData extends SignupUser({
  signup_data: new ProviderSignupData()
}) { }


export default function ProviderSignups() {
  const [loading, setLoading] = useState(true)
  const [users, setUsers] = useState<OrderedMap<string, ProviderUserWithSignupData>>(OrderedMap<string, ProviderUserWithSignupData>())
  const [showArchived, setShowArchived] = useState(false)
  const classes = onboardStyles();

  useEffect(() => {
    setLoading(true)
    fetchP({
      url: '/api/onboarding/signups/WORK',
      success: (json: any) => {
        setUsers(OrderedMap(json.users.map((u: any) => [u['email'], new ProviderUserWithSignupData(u)])))
        setLoading(false)
      }
    })
  }, [])

  if (loading) {
    return <h1>Loading provider signups...</h1>
  }

  function updateUser(u: any) {
    setUsers(users.set(u['email'], new ProviderUserWithSignupData(u)))
  }

  function DetailPanel(user: ProviderUserWithSignupData) {
    if (user.active) return <h1>User is active!</h1>

    return <Box className={classes.paper}>
      <Grid container
        direction="row"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item sm>
          <Grid container direction="column" justify="space-between" className={classes.maxHeight}>
            <Grid item>
              <JSONToDiv json={user.signup_data} />
            </Grid>
            <ActionsGridItem user={user} updateUser={updateUser} />
          </Grid>
        </Grid>
        <Grid item sm>
          <MsgBox user={user} />
        </Grid>
      </Grid>
    </Box>
  }

  return <>
    <MaterialTable
      title="Provider Signups"
      data={users.filter(u => showArchived ? u.archived : !u.archived).valueSeq().toArray()}
      columns={[
        { title: 'Date', field: 'created_date', type: 'datetime', render: row => moment(row.created_date).fromNow() },
        { title: 'Name', field: 'info.name' as any },
        { title: 'Contacted', field: 'contacted', type: 'boolean' },
        { title: 'Email', field: 'email', render: row => <a href={`mailto:${row.email}`}>{row.email}</a> },
        { title: 'Tel', field: 'signup_data.phone' as any, render: row => <a href={`tel:${row.signup_data.phone}`}>{row.signup_data.phone}</a> },
        { title: 'Services', field: 'serv_str' },
        { title: 'Languages', field: 'signup_data.languages' as any }
      ]}
      icons={tableIcons}
      detailPanel={DetailPanel}
      actions={[
        {
          icon: () => <>Show Archived:<Switch checked={showArchived} /></>,
          tooltip: 'Show Archived',
          isFreeAction: true,
          onClick: () => setShowArchived(!showArchived)
        }
      ]}
    />
  </>

}