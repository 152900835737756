import { makeStyles, Theme, createStyles } from '@material-ui/core';

const onboardStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },

    multiline: {
      whiteSpace: 'pre-line'
    },

    maxHeight: {
      height: '100%'
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
    }
  }),
);

export default onboardStyles