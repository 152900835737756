import fetchP from "src/fetchP"


export class Lang {
  key: string
  language: string
  script: string

  constructor(jsObj: any) {
    this.key = jsObj.key
    this.language = jsObj.language
    this.script = jsObj.script
  }

  public toString = (): string => {
    return this.language
  }
}

export const LANG_MAP: { [key: string]: Lang } = {}

export class LangPair {
  src: string = ``
  trg: string = ``

  constructor(jsObj: any) {
    this.src = jsObj.src
    this.trg = jsObj.trg
  }

  get key() {
    return `${this.src}+${this.trg}`
  }

  get src_lang(): Lang {
    const lang_map_val = LANG_MAP[this.src]
    return lang_map_val || new Lang({ key: this.src, language: this.src, script: `Loading...` })
  }

  get trg_lang(): Lang {
    const lang_map_val = LANG_MAP[this.trg]
    return lang_map_val || new Lang({ key: this.trg, language: this.trg, script: `Loading...` })
  }

  public toString = (): string => {
    return `${this.src_lang} → ${this.trg_lang}`;
  }
}

export class LangPairRate {
  lang_pair: LangPair = new LangPair({})
  rate?: number

  constructor(jsObj: any) {
    this.lang_pair = new LangPair(jsObj.lang_pair)
    this.rate = jsObj.rate
  }

  get key() {
    return this.lang_pair.key + this.rate
  }

  public toString = (): string => {
    return `${this.lang_pair} (${this.rate ? `₹${this.rate.toFixed(2)} per` : 'No Rate'})`
  }
}

export class LangRate {
  src: ''
  rate?: number

  constructor(jsObj: any) {
    this.src = jsObj.src
    this.rate = jsObj.rate
  }

  get lang(): Lang {
    const lang_map_val = LANG_MAP[this.src]
    return lang_map_val || new Lang({ key: this.src, language: this.src, script: `Loading...` })
  }
  get key() {
    return this.lang.key + this.rate
  }

  public toString = (): string => {
    return `${this.lang} (${this.rate ? `₹${this.rate.toFixed(2)} per` : 'No Rate'})`
  }
}

export function set_langs() {
  fetchP({
    url: '/api/langs',
    success: (resp: any) => {
      resp.langs.map((l: Lang) => {
        return LANG_MAP[l.key] = new Lang(l)
      })
    }
  })
}