import { Record } from "immutable";

interface ICert {
  key: string,
  participant_name: string,
  participant_email: string,
  course_name: string,
  date_conducted: string,
}

const defaultCertProps: ICert = {
  key: '',
  participant_name: '',
  participant_email: '',
  course_name: '',
  date_conducted: '',
}

export class Cert extends Record(defaultCertProps) implements ICert {
}