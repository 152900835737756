
import React, { useState } from 'react'
import { ReviewType, Invoice, Review } from 'src/types/invoice';
import {
  Dialog, DialogTitle, DialogContent, DialogActions,
  Button, RadioGroup, Radio,
  FormControlLabel, FormControl, FormGroup, TextField, FormLabel, CircularProgress,
} from '@material-ui/core';
import { ThumbDown, ThumbUp } from '@material-ui/icons';
import fetchP from 'src/fetchP';

interface IProps {
  invoice: Invoice,
  review_type: ReviewType,
  onClose: any,
  onSave: any
}

export default function ReviewModalEdit(props: IProps) {
  const { invoice, review_type, onClose, onSave } = props

  const orig_review = invoice.get_review(review_type)

  const [review, setReview] = useState(orig_review.created ?
    orig_review : new Review({ review_type, invoice_size: invoice.total * 100 }))

  const [saving, setSaving] = useState(false)

  function onSaveReview() {
    setSaving(true)
    fetchP({
      url: `/api/reviews/write_review/${invoice.uid}`,
      method: 'POST',
      body_obj: {
        review
      },
      success: (json: any) => {
        onSave(new Invoice(json.invoice))
        setSaving(false)
        onClose()
      }
    })
  }

  function setValue(prop: keyof Review, jsonParse?: boolean) {
    return function (e: any) {
      setReview(review.set(prop as any, jsonParse ? JSON.parse(e.target.value) : e.target.value))
    }
  }


  return <Dialog open={!!review_type} onClose={onClose} fullWidth={true}>

    <DialogTitle>{ReviewType[review_type]} Review #{invoice.uid}</DialogTitle>
    <DialogContent>
      <form>
        <FormGroup>
          <FormControl>
            <FormLabel component="legend">Work Again</FormLabel>
            <RadioGroup value={review.work_again === undefined ? '' : review.work_again} onChange={setValue("work_again", true)} row>
              <FormControlLabel value={true} control={<Radio />} label={<ThumbUp />} />
              <FormControlLabel value={false} control={<Radio />} label={<ThumbDown />} />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <FormLabel component="legend">Quality</FormLabel>
            <RadioGroup value={review.quality} onChange={setValue("quality", true)} row>
              {[1, 2, 3, 4, 5].map(n =>
                <FormControlLabel key={n} value={n} control={<Radio />} label={n} />)
              }
            </RadioGroup>
          </FormControl>
          <br />
          <FormControl>
            <TextField multiline variant="outlined" label="Comments"
              value={review.comments} onChange={setValue('comments')} />
          </FormControl>
          <br />
          <FormControl>
            <TextField multiline variant="outlined" label="Fieldscope Comments"
              value={review.comments_fs} onChange={setValue("comments_fs")} />
          </FormControl>
          <br />
          <FormControl>
            <TextField multiline variant="outlined" label="Size"
              value={review.invoice_size} onChange={setValue("invoice_size", true)} />
          </FormControl>
        </FormGroup>
      </form>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} variant="contained" color="primary">
        Cancel
      </Button>
      <Button disabled={saving} variant="contained" onClick={onSaveReview} color="primary">
        {saving && <CircularProgress size={24} color="inherit" />}Save
      </Button>
    </DialogActions>
  </Dialog >
}