import React from 'react'


export default function JSONToDiv(props: any) {
  const { json } = props

  return <div>
    {
      Object.keys(json).map(k => {
        const d = json[k]
        if (typeof (d) === 'string') {
          if (!Boolean(d)) return <div key={k} />
        } else if (typeof (d) === 'object') {
          if (Object.keys(d).length === 0) return <div key={k} />
        }

        return <div key={k}>
          <strong>{k}: </strong>
          <span style={{ whiteSpace: 'pre-line' }}>
            {typeof (d) === 'string' ?
              d
              :
              JSON.stringify(json[k])
            }
          </span>
        </div>
      })
    }
  </div>
}