import { InternalSetting, Routes, UserSetting } from "."
import { InternalSettingTypes, AdminPermission, UserSettingTypes } from "src/enums"
import { Record } from 'immutable';

export enum UserType {
  UNKNOWN = 0, CLIENT = 1, PROVIDER = 2
}

export interface IUser {
  key: string
  logged_in: boolean
  info: { name: string }
  email: string
  created?: Date
  locked: boolean
  allow_offline_billing: boolean
  active: boolean
  admin: boolean
  settings: Array<UserSetting>
  internal_settings: Array<InternalSetting>
  routes: Array<Routes>
  signup_data?: any
  user_type: UserType,
}

const defaultUserProps: IUser = {
  key: '',
  logged_in: false,
  info: Object({ name: `` }),
  email: ``,
  created: undefined,
  locked: true,
  allow_offline_billing: false,
  active: false,
  admin: false,
  settings: ([] as Array<UserSetting>),
  internal_settings: ([] as Array<InternalSetting>),
  routes: [],
  user_type: UserType.UNKNOWN,
}


export const UserBase = (extendedValues = {}) => class extends Record(
  { ...defaultUserProps, ...extendedValues }) implements IUser {

  getInternalSettingValue(setting_type: InternalSettingTypes): any {
    const perm = this.internal_settings.find(s => s.type === setting_type)
    if (!perm) throw new Error(`${setting_type} missing!`)
    return perm.value
  }

  getUserSettingValue(setting_type: UserSettingTypes): any {
    const perm = this.settings.find(s => s.type === setting_type)
    if (!perm) throw new Error(`${setting_type} missing!`)
    return perm.value
  }

  hasAdminPermission(perm: AdminPermission): boolean {
    return this.getInternalSettingValue(InternalSettingTypes.AdminPermissions).includes(perm)
  }

  get created_date() {
    return Date.parse(this.created as any)
  }

  get routes(): Array<Routes> {
    const routes = Array<Routes>()

    if (!this.logged_in) return routes

    const admin_permissions = this.getInternalSettingValue(InternalSettingTypes.AdminPermissions)


    if (admin_permissions.includes(AdminPermission.ACTIVITY)) {
      routes.push(Routes.ACTIVITY)
    }

    if (admin_permissions.includes(AdminPermission.USERS)) {
      routes.push(Routes.USERS)
    }

    if (admin_permissions.includes(AdminPermission.MAILER)) {
      routes.push(Routes.EMAIL)
    }

    if (admin_permissions.includes(AdminPermission.COURSES)) {
      routes.push(Routes.CERTS)
    }

    if (admin_permissions.includes(AdminPermission.ONBOARDING)) {
      routes.push(Routes.ONBOARDING_CLIENTS)
      routes.push(Routes.ONBOARDING_PROVIDERS)
    }

    if (admin_permissions.includes(AdminPermission.PROVIDERS)) {
      routes.push(Routes.PROVIDERS)
    }

    if (admin_permissions.includes(AdminPermission.CLIENTS)) {
      routes.push(Routes.PROJECTS)
      routes.push(Routes.ASSIGNMENTS)
    }

    if (admin_permissions.includes(AdminPermission.FINANCE)) {
      routes.push(Routes.INVOICES)
    }

    if (admin_permissions.includes(AdminPermission.REVIEWS)) {
      routes.push(Routes.REVIEWS)
    }

    return routes
  }
}

export class User extends UserBase() { }