import { Record } from "immutable";
import { AssignmentState } from "./index"

interface IAssgStats {
  busy: number
  experience: number
  perc_by_state: Array<EnumScore>
  work_by_state: Array<EnumScore>
}

const defaultProps: IAssgStats = {
  busy: 0,
  experience: 0,
  perc_by_state: [],
  work_by_state: []

}

interface EnumScore {
  enum: AssignmentState,
  score: number
}

export class AssgStats extends Record(defaultProps) implements IAssgStats {
  // constructor(jsObj?: any) {
  //   super({
  //     ...jsObj,
  //     perc_by_state: jsObj.perc_by_state.map()
  //   })
  // }

  perc(s: AssignmentState) {
    const p = this.perc_by_state.find((e, v) => e.enum === v)
    return p ? p.score : undefined
  }

  get state_map() {
    return this.work_by_state.map(es => ({
      enum: es.enum,
      work: es.score,
      perc: this.perc(es.enum)
    }))
  }
}