import { Record } from "immutable"
import { AssignmentState } from ".";

export enum AssignmentType {
  BASE = 0,
  TRANSLATOR = 1,
  TRANSCRIBER = 2,
  WRITER = 3
}



export enum CostType {
  CONTRACT_RATE = 0,
  FIXED = 1
}


export enum WorkUnit {
  word = 0,
  minute = 1
}

export class Cost extends Record({
  cost_type: CostType.CONTRACT_RATE,
  work_unit: WorkUnit.word,
  rate: 0,
  work: 0,
  total: 0
}) {

  get full_string() {
    return `${this.as_string} (${this.breakdown})`
  }

  get breakdown() {
    switch (this.cost_type) {
      case CostType.CONTRACT_RATE:
        return `${this.work} ${WorkUnit[this.work_unit]}${this.work > 1 ? 's' : ''} @ ₹${this.rate / 100} per`
      case CostType.FIXED:
        return `FIXED`
      default:
        return 'ERROR'
    }
  }

  get as_string() {
    return `₹${this.total / 100}`
  }
}

export class Assignment extends Record({
  key: '',
  created: '',
  updated: '',
  assignment_type: AssignmentType.BASE,
  state: AssignmentState.ASSIGNED,
  cost: new Cost(),
  project_key: '',
  project_title: '',
  document_key: '',
  document_name: '',
  profile_key: '',
  profile_name: '',
  creator_name: ''
}) {
  constructor(jsObj: any) {
    super({
      ...jsObj,
      cost: new Cost(jsObj.cost)
    })
  }
  get created_date() {
    return Date.parse(this.created as any)
  }

  get updated_date() {
    return Date.parse(this.updated as any)
  }
}