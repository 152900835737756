import React, { useState, useEffect, useRef } from 'react'
import fetchP from 'src/fetchP'
import MaterialTable from 'material-table'
import tableIcons from 'src/table_icons'
import { Cert } from 'src/types/cert'
import {
  Dialog, DialogTitle, DialogContent,
  TextField, Table, TableRow, TableBody, TableHead, TableCell, Grid, DialogActions, Button
} from '@material-ui/core'

import { AddCircleOutline, Close, CheckCircle, PictureAsPdf, DeleteForever } from '@material-ui/icons'

import Markdown from 'markdown-to-jsx';


export default function Certs() {
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  // const classes = useStyles();

  const [certs, setCerts] = useState([] as Array<Cert>)

  useEffect(() => {
    setLoading(true)
    fetchP({
      url: '/api/courses/certs',
      success: updateCertificates
    })
  }, [])

  if (loading) {
    return <h1>Loading Certificates...</h1>
  }

  function updateCertificates(json: any) {
    setCerts(json.certificates.map((c: any) => new Cert(c)))
    setLoading(false)
  }

  const onClose = () => setOpen(false)

  return <>
    <MaterialTable
      title="Certificates"
      icons={tableIcons}
      columns={[
        { title: 'Course Name', field: 'course_name', defaultGroupOrder: 0 },
        { title: 'Course Date', field: 'date_conducted' },
        { title: 'Name', field: 'participant_name' },
        { title: 'Email', field: 'participant_email' },

      ]}
      data={certs}
      options={{
        grouping: true
      }}
      actions={[
        {
          isFreeAction: true,
          icon: () => <AddCircleOutline />,
          tooltip: 'Add Certificate',
          onClick: () => setOpen(true),
        },
        {
          icon: () => <PictureAsPdf />,
          tooltip: 'View Certificate',
          onClick: (_, cert: any) => window.open(`/api/courses/cert_pdf/${cert.key}`),
        },
        {
          icon: () => <DeleteForever />,
          tooltip: 'Delete',
          onClick: (_, cert: any) => {
            if (window.confirm('Are you sure you want to delete this certificate?')) {
              fetchP({
                url: `/api/courses/cert_delete/${cert.key}`,
                method: 'POST',
                success: updateCertificates
              })
            }
          },
        }
      ]}

    />
    <AddCertificateDialog {...{ open, onClose, updateCertificates }} />
  </>
}
interface AddCertificateDialogProps {
  open: boolean,
  onClose: any,
  updateCertificates: any,
}

function emailIsValid(email: string) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

function AddCertificateDialog(props: AddCertificateDialogProps) {
  const { open, onClose, updateCertificates } = props
  const [courseName, setCourseName] = useState('')
  const [courseDate, setCourseDate] = useState('')
  const [namesAndEmails, setNamesAndEmails] = useState('')

  const [subline, setSubline] = useState('')

  const [facilitatorName, setFacilitatorName] = useState('')
  const [facilitatorTitle, setFacilitatorTitle] = useState('')
  const [facilitatorSignatureUrl, setFacilitatorSignatureUrl] = useState('')

  const participants = namesAndEmails ? namesAndEmails.split('\n').map(s => s.split(',').map(s => s.trim())) : []

  const subline_el = useRef<any>()

  function createCertificates() {
    fetchP({
      url: '/api/courses/create_certificates',
      method: 'POST',
      body_obj: {
        courseName,
        courseDate: Date.parse(courseDate),
        subline_md: subline, subline_html: subline_el.current.innerHTML,
        facilitatorName, facilitatorTitle, facilitatorSignatureUrl,
        participants
      },
      success: (json: any) => {
        if (!json['success']) {
          alert("Create Certificates failed!")
        } else {
          alert('Success!')
          updateCertificates(json)
          onClose()
        }
      }
    })
  }

  return <Dialog
    open={open} onClose={onClose}
    fullWidth={true} maxWidth="lg"
    aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">Create Certificates</DialogTitle>
    <DialogContent>
      <TextField
        value={courseName}
        onChange={e => setCourseName(e.target.value)}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        label="Course Name"
        name="Course Name"
        autoFocus
      />
      <br /><br />
      <TextField
        id="date"
        variant="outlined"
        label="Course Date"
        type="date"
        onChange={e => setCourseDate(e.target.value)}
        value={courseDate}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <br /><br />
      <Grid container >
        <Grid item xs={12}>
          <TextField
            value={subline}
            onChange={e => setSubline(e.target.value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Subline Markdown"
            name="Subline Markdown"
          />
          <SublineContents contents={subline} contents_el={subline_el} />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={facilitatorName}
            onChange={e => setFacilitatorName(e.target.value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Facilitator Name"
            name="Facilitator Name"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={facilitatorTitle}
            onChange={e => setFacilitatorTitle(e.target.value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Facilitator Title"
            name="Facilitator Title"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={facilitatorSignatureUrl}
            onChange={e => setFacilitatorSignatureUrl(e.target.value)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Facilitator Signature URL"
            name="Facilitator Signature URL"
          />
          <img alt="Enter Signature URL" src={facilitatorSignatureUrl} style={{ maxWidth: '200px', maxHeight: '200px' }} />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextField
            id="outlined-multiline-static"
            label="Participants"
            multiline
            variant="outlined"
            value={namesAndEmails}
            onChange={e => setNamesAndEmails(e.target.value)}
            style={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={6}>

          <Table size="small" aria-label="Participant Names">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Valid Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {participants.map(([name, email]) =>
                <TableRow key={email || "empty"}>
                  <TableCell>{name}</TableCell>
                  <TableCell>{email}</TableCell>
                  <TableCell>{emailIsValid(email) ? <CheckCircle /> : <Close />}</TableCell>
                </TableRow>)}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
          </Button>
        <Button disabled={participants.length <= 0} onClick={createCertificates} color="primary">
          Create {participants.length} Certificate{participants.length === 1 ? '' : 's'}
        </Button>
      </DialogActions>

    </DialogContent>
  </Dialog>
}

interface SublineContentsProps {
  contents: any,
  contents_el: any
}

function SublineContents(props: SublineContentsProps) {
  return <div ref={props.contents_el}>
    <Markdown>{props.contents}</Markdown>
  </div>
}