import React from 'react';
import { NavLink } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';


interface ListItemLink {
  classes: any
  icon: JSX.Element
  title: string
  to: any
}

export default function ListItemLink(props: ListItemLink) {
  const { classes, icon, title, to } = props;

  return (
    <ListItem button component={NavLink} to={to} activeClassName={classes.link_active}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={title} />
    </ListItem>
  );
}