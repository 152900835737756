import React, { useState, useEffect, useRef } from 'react'
import fetchP from 'src/fetchP'
import MaterialTable, { MTableGroupRow } from 'material-table'
import tableIcons from 'src/table_icons'
import { Provider, ProfileType } from 'src/types/provider'
import { Grid, Box, Switch } from '@material-ui/core'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { AssignmentState } from 'src/types'
import { AssignmentAction, ProfileView } from '../clients/assignments'
import { MonetizationOn } from '@material-ui/icons'
import { ProviderPaymentInfoModal } from './payment_modal'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }),
);


export default function Providers() {
  const [loading, setLoading] = useState(true)
  const [providers, setProviders] = useState<Array<Provider>>([])
  const [byLP, setByLP] = useState(false)

  const payments_modal = useRef()

  useEffect(() => {
    setLoading(true)
    fetchP({
      url: '/api/providers/providers',
      success: (json: any) => {
        setProviders(json.providers.map((p: any) => new Provider(p)))
        setLoading(false)
      }
    })
  }, [])

  if (loading) {
    return <h1>Loading Providers...</h1>
  }

  const providers_with_lpr = providers.map(p => p.lang_pair_rates.map(lpr => p.set('lp_col', lpr.lang_pair))) as any as Array<Provider>

  const data = byLP ? providers_with_lpr : providers

  return <>
    <ProviderPaymentInfoModal ref={payments_modal} />
    <MaterialTable
      title="Providers"
      icons={tableIcons}
      columns={[
        { title: 'Name', field: 'info.name' as any },
        { title: 'Type', field: 'profile_type', lookup: ProfileType, defaultGroupOrder: 0 },
        { title: 'Available', type: 'boolean', field: 'available' },
        { title: 'Negotiable', type: 'boolean', field: 'negotiable' },
        { title: 'GST', type: 'boolean', field: 'has_gst' },
        { title: 'Busy', type: 'numeric', field: 'user_stats.busy' as any },
        { title: 'Experience', type: 'numeric', field: 'user_stats.experience' as any },
        byLP ? { title: 'Language', field: 'lang_str', defaultGroupOrder: 1 } : {}
      ]}
      detailPanel={DetailPanel}
      onRowClick={(event, rowData, togglePanel) => togglePanel && togglePanel()}
      data={data}
      options={{
        grouping: true
      }}
      actions={[
        AssignmentAction('profile', (row: Provider) => row.key),
        ProfileView((row: Provider) => row.key),
        {
          icon: () => <MonetizationOn />,
          tooltip: 'Payments Info',
          onClick: (event: any, row: any) => {
            (payments_modal.current as any).show(row)
          }
        },
        {
          icon: () => <>Language Pair:<Switch checked={byLP} /></>,
          tooltip: 'Entry for each Language Pair ',
          isFreeAction: true,
          onClick: () => setByLP(!byLP)
        }
      ]}
      components={{
        GroupRow: GroupRowWithCount
      }}
    />

  </>
}


function DetailPanel(profile: Provider) {
  const classes = useStyles();
  return <Box className={classes.paper}>
    <Grid alignItems="center" container spacing={3}>
      <Grid item xs={2}>

        {profile.lang_pair_rates.map(lpr =>
          <div key={lpr.key}>{`${lpr}`}</div>)
        }
        {profile.lang_rates.map(lr =>
          <div key={lr.key}>{`${lr}`}</div>)
        }

      </Grid>
      <Grid item xs>
        {profile.description}
      </Grid>
      <Grid item xs>
        <strong>Profile Stats</strong>
        {profile.profile_stats.state_map.map(v => <div key={v.enum}>
          <strong>{AssignmentState[v.enum]}</strong>: ₹{(v.work / 100).toFixed(2)} ({v.perc}%)
          </div>)}
      </Grid>
      <Grid item xs>
        <strong>User Stats</strong>
        {profile.user_stats.state_map.map(v => <div key={v.enum}>
          <strong>{AssignmentState[v.enum]}</strong>: ₹{(v.work / 100).toFixed(2)} ({v.perc}%)
          </div>)}
      </Grid>
    </Grid>
  </Box>
}

function GroupRowWithCount(props: any) {
  const { groupData } = props;
  const count = groupData.data.length || groupData.groups.length
  return <>
    <tr>
      {Array(props.level + 1).fill(null).map((x, i) => <td key={i} />)}
      <td colSpan={props.columns.length} >Count: {count}</td>
    </tr>
    <MTableGroupRow {...props} />
  </>

}