import React, { useState } from 'react';

import { TextField, Button, Container } from '@material-ui/core';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    toolbar: {
      ...theme.mixins.toolbar,
      marginTop: -theme.spacing(1),
    },
    login_paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }))


export default function Login(props: any) {
  const classes = useStyles();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { setUser } = props;

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)

  async function loginSubmit(e: React.FormEvent) {
    e.preventDefault()
    const recaptcha_response = await executeRecaptcha("login_page");

    await fetch('/api/auth/login', {
      method: 'POST',
      body: JSON.stringify({
        email, password, recaptcha_response
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())

    const resp = await fetch('/api/users/status').then(res => res.json())
    if (resp.success) {
      setLoading(false)
      setUser(resp['user'])
    } else {
      setUser(false)
    }
    setPassword("")
  }

  return <Container maxWidth="xs">
    <div className={classes.login_paper}>
      <form className={classes.form} onSubmit={loginSubmit} noValidate>
        <TextField
          value={email}
          onChange={e => setEmail(e.target.value)}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <TextField
          value={password}
          onChange={e => setPassword(e.target.value)}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
        />
        <Button
          disabled={loading}
          fullWidth
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Sign In
  </Button>
      </form>
    </div>
  </Container>
}