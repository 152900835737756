import React, { useState, useImperativeHandle, forwardRef, useRef } from 'react'

import {
  Dialog, DialogContent, Button,
} from '@material-ui/core';
import ActivityTable from './table';
import { History, } from '@material-ui/icons';

export const ActivityModal = forwardRef((props: any, ref: any) => {
  const [visible, setVisible] = useState(false)

  const parent = useRef('')

  useImperativeHandle(ref, () => ({
    show: (_row: any) => {
      parent.current = _row.key
      setVisible(true)
    }
  }))
  return <Dialog open={visible} onClose={() => setVisible(false)} fullWidth={true} maxWidth={false}>
    <DialogContent>
      <ActivityTable parent={parent.current} />
    </DialogContent>
  </Dialog >
})


export function ActivityColumn(activity_modal: any) {
  return {
    title: '',
    render: (row: any) =>
      <Button onClick={e => {
        activity_modal.current && (activity_modal.current as any).show(row)
        e.stopPropagation()
      }}>
        <History />
      </Button>
  }
}

export function ActivityAction(activity_modal: any) {
  return {
    icon: () => <History />,
    tooltip: 'Activity',
    onClick: (event: any, row: any) => {
      activity_modal.current && (activity_modal.current as any).show(row)
    }
  }
}