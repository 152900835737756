import React, { useState, useRef } from 'react'

import './email.css'
import { TextField, Button, Grid, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import fetchP from 'src/fetchP'
import EmailContents from './contents';

enum SendTo {
  SpecificEmail,
  ALL_CLIENTS,
  ALL_PROVIDERS,
  ALL_USERS
}

export default function Email() {
  const [send_to, setSendTo] = useState(SendTo.SpecificEmail)
  const [email, setEmail] = useState('nav@fieldscope.in')
  const [subject, setSubject] = useState('')
  const [title, setTitle] = useState('TITLE')
  const [contents, setContent] = useState("# Contents")
  const content_el = useRef<any>()

  function send() {

    if (window.confirm(`ARE YOU SURE?!?!?!\n
    Subject: ${subject}\n
    Title:${title}\n\n
    Send to ***${SendTo[send_to]}***?`)) {
      fetchP({
        url: '/api/users/send_mailer',
        method: 'POST',
        body_obj: { send_to, email, subject, title, contents_md: contents, contents_html: content_el.current.innerHTML },
        success: (json: any) => {
          if (json.message) alert(json.message)
          else alert(json.reason)
        }
      })
    }
  }

  return <>
    <Grid
      container
      direction="column"
      spacing={1}
    >
      <Grid item>
        <RadioGroup value={send_to} onChange={e => setSendTo(parseInt(e.target.value))} row>
          <FormControlLabel value={SendTo.SpecificEmail} control={<Radio />} label='Email:' />
          <FormControlLabel value={SendTo.ALL_CLIENTS} control={<Radio />} label='ALL Clients' />
          <FormControlLabel value={SendTo.ALL_PROVIDERS} control={<Radio />} label='ALL Providers' />
          <FormControlLabel value={SendTo.ALL_USERS} control={<Radio />} label='ALL Users' />
        </RadioGroup>
        {send_to === SendTo.SpecificEmail &&
          <TextField variant="outlined" value={email} onChange={e => setEmail(e.target.value)} />
        }
      </Grid>
      <Grid item>
        <TextField variant="outlined" fullWidth placeholder="Subject " value={subject} onChange={e => setSubject(e.target.value)} />

      </Grid>
      <Grid item>

        <TextField variant="outlined" fullWidth value={title} onChange={e => setTitle(e.target.value)} />
      </Grid>
      <Grid item>

        <TextField variant="outlined" fullWidth multiline value={contents} onChange={e => setContent(e.target.value)} />
      </Grid>
      <Grid item>
        <Button variant="contained" onClick={send}>SEND EMAIL</Button>
      </Grid>

    </Grid>

    <hr />
    <EmailContents title={title} contents={contents} content_el={content_el} />
  </>
}