import React, { useState, useRef } from 'react'
import { User } from 'src/types'
import { InternalSettingTypes, AdminPermission } from 'src/enums'

import { Dialog, DialogTitle, DialogContent, DialogContentText, Button, DialogActions, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import fetchP from 'src/fetchP';

interface AdminPermissionDialog {
  user: User,
  onClose: any,
  onSave: any
}

export default function AdminPermissionDialog(props: AdminPermissionDialog) {
  const { user, onClose, onSave } = props


  const orig_perm = useRef(user.getInternalSettingValue(InternalSettingTypes.AdminPermissions))

  const [userPerm, setUserPerm] = useState<Array<AdminPermission>>(orig_perm.current)
  const [saving, setSaving] = useState<boolean>(false)

  function hasAdminPermission(perm: AdminPermission): boolean {
    return userPerm.includes(perm)
  }


  function handlePermDialogSave() {
    setSaving(true)
    fetchP({
      url: '/api/users/update_internal_settings',
      method: 'POST',
      body_obj: {
        email: user.email,
        updates: [[
          InternalSettingTypes.AdminPermissions,
          userPerm.map(p => AdminPermission[p])
        ]]
      },
      success: onSave
    })
  }


  const handleCheck = (perm: AdminPermission) => () => {
    if (hasAdminPermission(perm)) {
      setUserPerm(userPerm.filter((p: any) => p !== perm))
    } else {
      setUserPerm([...userPerm, perm])
    }
  }

  return <Dialog open={!!user} onClose={onClose} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">Permissions: {user.info.name} {`<${user.email}>`}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Administrator Permissions
    </DialogContentText>

      <FormGroup>
        {
          Object.keys(AdminPermission).map(perm_str => {
            const perm = perm_str as AdminPermission
            return <FormControlLabel key={perm}
              control={
                <Checkbox
                  checked={hasAdminPermission(perm)}
                  onChange={handleCheck(perm)}
                  value={perm}
                />
              }
              label={perm_str}
            />
          })
        }
      </FormGroup>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Cancel
      </Button>
      <Button disabled={saving} onClick={handlePermDialogSave} color="primary">
        Save
      </Button>
    </DialogActions>

  </Dialog>
}