import React, { useState, useEffect } from 'react'
import {
  makeStyles, Theme, createStyles, Box, Button, Link, Switch, TableCell, Table, TableBody, TableRow, ButtonGroup,
  Dialog, DialogTitle, DialogContent, TextField, DialogContentText, DialogActions, InputAdornment
}
  from '@material-ui/core';

import MaterialTable from 'material-table'
import tableIcons from 'src/table_icons'
import { OrderedMap } from 'immutable'
import fetchP from 'src/fetchP'
import moment from 'moment'
import { PictureAsPdf, Warning, } from '@material-ui/icons';
import { Invoice, InvoiceState } from 'src/types/invoice';
import ReviewLabel from 'src/components/review/label';





export default function Invoices() {
  const [invoices, setInvoices] = useState<OrderedMap<string, Invoice>>(OrderedMap())
  const [loading, setLoading] = useState(true)
  const [allowFiltering, setAllowFiltering] = useState(false)

  const [adjustingInvoice, setAdjustingInvoice] = useState<Invoice>()
  const classes = useStyles()

  useEffect(() => {
    setLoading(true)
    fetchP({
      url: '/api/finance/invoices',
      success: (json: any) => {
        setInvoices(OrderedMap(json.invoices.map((i: any) => [i['uid'], new Invoice(i)])))
        setLoading(false)
      }
    })
  }, [])

  const forcePaid = (invoice: Invoice) => () => {
    if (window.confirm(`Are you sure you want to mark Invoice #${invoice.uid} as paid?`)) {
      fetchP({
        url: '/api/finance/force_paid',
        method: 'POST',
        body_obj: {
          invoice_uid: invoice.uid,
        },
        success: (json: any) => {
          if (!json['success']) {
            alert("Action failed!")
          } else {
            onInvoiceUpdate([json['invoice']])
          }
        }
      })
    }
  }

  function onInvoiceUpdate(invoices_json: Array<any>) {
    let invoices_ptr = invoices
    for (const inv_js of invoices_json) {
      invoices_ptr = invoices_ptr.set(inv_js['uid'], new Invoice(inv_js))
    }

    setInvoices(invoices_ptr)
  }

  if (loading) return <h1>Loading Invoices...</h1>

  function DetailPanel(invoice: Invoice) {
    return <Box className={classes.paper}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell><strong>Invoice Date: </strong>{moment(invoice.created_date).format('lll')}</TableCell>
            <TableCell>
              <Button
                component={Link}
                href={invoice.pdf_c}
                target='_blank'
                startIcon={<PictureAsPdf />}
              >Provider → Client</Button>
            </TableCell>
            <TableCell>
              <Button
                component={Link}
                href={invoice.pdf_s}
                target='_blank'
                startIcon={<PictureAsPdf />}
              >Fieldscope → Provider</Button>
            </TableCell>
            {invoice.state === InvoiceState.REQUEST &&
              <TableCell>
                <ButtonGroup>
                  <Button variant="outlined"
                    onClick={() => setAdjustingInvoice(invoice)}>Override Service Fee</Button>
                  <Button variant="outlined" color="secondary"
                    startIcon={<Warning />}
                    onClick={forcePaid(invoice)}>Force as Paid</Button>
                </ButtonGroup>
              </TableCell>
            }
          </TableRow>
          <TableRow>
            <TableCell />
            <TableCell>Review of Client: <ReviewLabel review={invoice.review_client} /></TableCell>
            <TableCell>Review of Provider: <ReviewLabel review={invoice.review_provider} /></TableCell>
            <TableCell>
              Work: ₹{invoice.work}<br />
              Service: ₹{(invoice.total - invoice.work).toFixed(2)}<br />

              {invoice.service_rate_override === null ?
                <>Service Rate: {invoice.project.billing.comission_rate}%</>
                :
                <>
                  Service Rate: {invoice.service_rate_override}% (Orig: {invoice.project.billing.comission_rate}%)
              </>
              } <br />


            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  }

  return <>
    <MaterialTable
      title="Invoices"
      icons={tableIcons}
      detailPanel={DetailPanel}
      columns={[
        { title: 'Updated', field: 'updated_date', type: 'datetime', defaultSort: "desc", render: row => moment(row.updated_date).fromNow(), grouping: false, filtering: false },
        { title: 'ID#', field: 'uid', grouping: false, filtering: false },
        { title: 'Project', field: 'project.title' as any },
        { title: 'Provider', field: 'provider_payments.name' as any },
        { title: 'Total', field: 'total', type: 'currency', currencySetting: { locale: 'en-IN', currencyCode: 'INR' }, grouping: false, filtering: false },
        { title: 'GST-Month', field: 'gst_month' },
        { title: 'State', field: 'state', lookup: InvoiceState }
      ]}
      onRowClick={(event, rowData, togglePanel) => togglePanel && togglePanel()}
      data={invoices.valueSeq().toArray()}
      actions={[
        {
          icon: () => <>Filtering:<Switch checked={allowFiltering} /></>,
          tooltip: 'Filtering',
          isFreeAction: true,
          onClick: () => setAllowFiltering(!allowFiltering)
        }
      ]}
      options={{
        grouping: true,
        filtering: allowFiltering
      }}
    />
    {adjustingInvoice && <InvoiceAdjustDialog
      invoice={adjustingInvoice}
      onClose={() => setAdjustingInvoice(undefined)}
      onDone={onInvoiceUpdate}
    />}
  </>
}

interface InvoiceAdjustDialog {
  invoice: Invoice,
  onClose: any,
  onDone: any,
}

function InvoiceAdjustDialog(props: InvoiceAdjustDialog) {
  const { invoice, onClose, onDone } = props
  const [rate, setRate] = useState()
  const [saving, setSaving] = useState(false)

  function setRateFiltered(value: string) {
    if (!(/^\d*$/.test(value))) return;
    const val_int = Number.parseInt(value)
    if (val_int > 100) return
    setRate(val_int)
  }

  function onOverrideServiceRate() {
    if (window.confirm(`Are you sure you override Service Rate to ${rate}%?`)) {
      setSaving(true)
      fetchP({
        url: '/api/finance/override_service_rate',
        method: 'POST',
        body_obj: {
          invoice_uid: invoice.uid,
          rate
        },
        success: (json: any) => {
          setSaving(false)
          if (!json['success']) {
            alert("Action failed!")
          } else {
            onDone([json['old_invoice'], json['new_invoice']])
            onClose()
          }
        }
      })
    }

  }



  return <Dialog open={!!invoice} onClose={onClose} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">Invoice: {invoice.uid}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        <strong>Project Rate: {invoice.project.billing.comission_rate}%</strong><br />
        {invoice.service_rate_override !== null && <>
          <strong>Service Fee Override: {invoice.service_rate_override}%</strong><br />
        </>}

        This invoice will be cancelled and a new invoice with adjusted service rate will be issued.<br /><br />
        Enter new service rate:
      </DialogContentText>
      <TextField
        size="small"
        variant="outlined"
        value={Number.isInteger(rate) ? rate : ''}
        onChange={e => setRateFiltered(e.target.value)}
        autoComplete="New Service Fee"
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
          style: { textAlign: "right" }
        }}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Cancel
    </Button>
      <Button disabled={saving || !Number.isInteger(rate)} onClick={onOverrideServiceRate} color="primary">
        Adjust Service Rate
    </Button>
    </DialogActions>
  </Dialog >

}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
    }
  }),
);