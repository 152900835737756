import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react'
import tableIcons from 'src/table_icons'
import MaterialTable from 'material-table'
import fetchP from 'src/fetchP'
import moment from 'moment'
import { ActivityModal, ActivityAction } from 'src/components/activity/modal'

import { Map } from 'immutable';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core'
import { ProfileType } from 'src/types/provider'
import { Project } from 'src/types/project'
import { AssignmentAction } from './assignments'
import { Edit } from '@material-ui/icons'
import { User } from 'src/types'
import { UserType } from 'src/types/user'

export default function Projects() {
  const [loading, setLoading] = useState(true)
  const [projects, setProjects] = useState<Array<Project>>([])
  const activity_modal = useRef()
  const edit_owner_modal = useRef()

  useEffect(() => {
    setLoading(true)
    refreshProjects()
  }, [])

  function refreshProjects() {
    fetchP({
      url: '/api/clients/projects',
      success: (json: any) => {
        setProjects(json.projects.map((p: any) => new Project(p)))
        setLoading(false)
      }
    })
  }

  if (loading) return <h1>Loading Projects...</h1>

  function DetailPanel(project: Project) {
    return <Box><h3 style={{ textAlign: 'center' }}>{project.title}</h3>
      <Grid alignItems="center" justify="space-evenly" container spacing={1}>
        <Grid item>{project.reqs.map(req => <div key={req.key}>
          <strong>{ProfileType[req.profile_type]}: </strong> {req.toString()}
        </div>)}</Grid>
        <Grid item>{project.brief}</Grid>
      </Grid>
    </Box>
  }

  return <>
    <MaterialTable
      title="Projects"
      icons={tableIcons}
      actions={[
        ActivityAction(activity_modal),
        AssignmentAction('project', (row: any) => row.key),
        ChangeOwner(edit_owner_modal),
      ]}
      columns={[
        { title: 'Created', field: 'created_date', type: 'datetime', render: row => moment(row.created_date).fromNow() },
        { title: 'Owner', field: 'owner' },
        { title: 'Org', field: 'org' as any },
        { title: 'Title', field: 'title' as any },
        { title: 'Contracts', type: 'boolean', field: 'has_contracts' },
        { title: 'Assignments', type: 'boolean', field: 'has_assignments' },
        { title: 'Billing', type: 'boolean', field: 'billing.enabled' as any },
        { title: 'Offline Billing', type: 'boolean', field: 'billing.offline_billing' as any },
        { title: 'Invoices', type: 'boolean', field: 'has_invoices' },
      ]}
      detailPanel={DetailPanel}
      onRowClick={(event, rowData, togglePanel) => togglePanel && togglePanel()}
      data={projects}
      options={{
        grouping: true
      }}
    />
    <ActivityModal ref={activity_modal} />
    <EditOwnerModal ref={edit_owner_modal} refreshProjects={refreshProjects} />
  </>
}

export const EditOwnerModal = forwardRef((props: any, ref: any) => {
  const { refreshProjects } = props;
  const [visible, setVisible] = useState(false)

  const [users, setUsers] = useState<Map<string, User>>(Map<string, User>())
  const [new_owner, setNewOwner] = useState('')

  const projectRow = useRef()

  useImperativeHandle(ref, () => ({
    show: (_row: any) => {
      projectRow.current = _row
      setNewOwner('')
      setVisible(true)
    }
  }))


  useEffect(() => {
    if (!visible) return;
    fetchP({
      url: '/api/users/users',
      success: (json: any) => {
        setUsers(Map(json.users.map((u: any) => [u['email'], new User(u)])).filter(user => (user as User).user_type === UserType.CLIENT) as any)
      }
    })

  }, [visible])

  const project = projectRow.current as any
  function onClose() { setVisible(false) };
  if (!project) return <React.Fragment />

  function handleChangeOwner() {

    if (!window.confirm(`Change owner of Project (${project.title})?`)) return
    setNewOwner('')
    fetchP({
      url: '/api/clients/change_project_owner',
      method: 'POST',
      body_obj: {
        email: new_owner,
        project_key: project.key,
      },
      success: (json: any) => {
        alert("OWNER CHANGED");
        refreshProjects();
        onClose();
      }
    })
  }

  return <Dialog open={visible} onClose={onClose} >
    <DialogTitle>Change Owner of "{project.title}"</DialogTitle>
    <DialogContent>
      <p>Current owner: <strong>{project.team[0].name}</strong></p>
      <div>
        <FormControl style={{ width: '100%' }}>
          <InputLabel id="new-owner-label">Select New Owner</InputLabel>
          <Select
            labelId="new-owner-label"
            id="demo-simple-select"
            value={new_owner}
            onChange={e => setNewOwner(e.target.value as any)}
          >
            {users.map(user => {
              return <MenuItem key={user.key} value={user.email}>{user.info.name} ({user.email})</MenuItem>
            }
            )}
          </Select>
        </FormControl>
      </div>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Cancel
    </Button>
      <Button disabled={!new_owner} onClick={handleChangeOwner} variant="outlined" color="secondary">
        Change Owner
    </Button>
    </DialogActions>
  </Dialog >
})

export function ChangeOwner(edit_owner: any) {
  return {
    icon: () => <Edit />,
    tooltip: 'Change Owner',
    onClick: (event: any, row: any) => {
      edit_owner.current && (edit_owner.current as any).show(row)
    }
  }
}