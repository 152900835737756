import React, { useContext } from 'react';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';

import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import { AccountCircleSharp, FaceTwoTone, SupervisedUserCircleTwoTone, WorkTwoTone, MonetizationOnTwoTone, WorkOutline, TimelapseTwoTone, RateReview, AssignmentIndTwoTone, Email, Note } from '@material-ui/icons';

import { Typography } from '@material-ui/core';
import { UserContext } from 'src/UserContext';
import { Routes } from 'src/types';
import ListItemLink from 'src/components/list_item_link';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      [theme.breakpoints.up('lg')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    toolbar: theme.mixins.toolbar as CSSProperties,
    drawerPaper: {
      width: drawerWidth,
    },
    drawerSubtitle: {
      paddingLeft: theme.spacing(2)
    },
    link_active: {
      backgroundColor: theme.palette.action.selected
    }
  }),
);


function NavDrawer(props: any) {
  const user = useContext(UserContext)
  const { mobileOpen, handleDrawerToggle } = props;
  const theme = useTheme();
  const classes = useStyles(theme);

  const routes = user.routes;

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <List>
        {routes.includes(Routes.USERS) &&
          <ListItemLink
            title="Users"
            icon={<SupervisedUserCircleTwoTone />}
            to={`/${Routes[Routes.USERS]}`}
            classes={classes}
          />

        }
        {routes.includes(Routes.ACTIVITY) &&

          <ListItemLink
            title="Activity"
            icon={<TimelapseTwoTone />}
            to={`/${Routes[Routes.ACTIVITY]}`}
            classes={classes}
          />
        }
        {routes.includes(Routes.EMAIL) &&
          <ListItemLink
            title="Email"
            icon={<Email />}
            to={`/${Routes[Routes.EMAIL]}`}
            classes={classes}
          />
        }
      </List>
      {routes.includes(Routes.CERTS) &&
        <>
          <Typography className={classes.drawerSubtitle} variant="h6" noWrap>Courses</Typography>
          <Divider />
          <List>
            <ListItemLink
              title="Certificates"
              icon={<Note />}
              to={`/${Routes[Routes.CERTS]}`}
              classes={classes}
            />
          </List>
        </>
      }
      {(
        routes.includes(Routes.ONBOARDING_CLIENTS)
        || routes.includes(Routes.ONBOARDING_PROVIDERS))
        && <>
          <Typography className={classes.drawerSubtitle} variant="h6" noWrap>Signups</Typography>
          <Divider />
          <List>
            {routes.includes(Routes.ONBOARDING_CLIENTS) &&
              <ListItemLink
                title="Clients"
                icon={<WorkOutline />}
                to={`/${Routes[Routes.ONBOARDING_CLIENTS]}`}
                classes={classes}
              />
            }
            {routes.includes(Routes.ONBOARDING_PROVIDERS) &&
              <ListItemLink
                title="Providers"
                icon={<FaceTwoTone />}
                to={`/${Routes[Routes.ONBOARDING_PROVIDERS]}`}
                classes={classes}
              />
            }
          </List>
        </>
      }
      {routes.includes(Routes.PROJECTS) && <>
        <Typography className={classes.drawerSubtitle} variant="h6" noWrap>Clients</Typography>
        <Divider />
        <List>
          <ListItemLink
            title="Projects"
            icon={<WorkTwoTone />}
            to={`/${Routes[Routes.PROJECTS]}`}
            classes={classes}
          />
          {routes.includes(Routes.ASSIGNMENTS) &&
            <ListItemLink
              title="Assignments"
              icon={<AssignmentIndTwoTone />}
              to={`/${Routes[Routes.ASSIGNMENTS]}`}
              classes={classes}
            />
          }
        </List>
      </>
      }
      {routes.includes(Routes.PROVIDERS) && <>
        <Typography className={classes.drawerSubtitle} variant="h6" noWrap>Provider</Typography>
        <Divider />
        <List>
          <ListItemLink
            title="Providers"
            icon={<AccountCircleSharp />}
            to={`/${Routes[Routes.PROVIDERS]}`}
            classes={classes}
          />

        </List>
      </>
      }

      {(routes.includes(Routes.INVOICES) || routes.includes(Routes.REVIEWS)) && <>
        <Typography className={classes.drawerSubtitle} variant="h6" noWrap>Finance</Typography>
        <Divider />
        <List>
          {routes.includes(Routes.INVOICES) && <>
            <ListItemLink
              title="Invoices"
              icon={<MonetizationOnTwoTone />}
              to={`/${Routes[Routes.INVOICES]}`}
              classes={classes}
            />
          </>
          }
          {routes.includes(Routes.REVIEWS) && <>
            <ListItemLink
              title="Reviews"
              icon={<RateReview />}
              to={`/${Routes[Routes.REVIEWS]}`}
              classes={classes}
            />
          </>
          }
        </List>
      </>}
    </div>
  );

  return <nav className={classes.drawer} aria-label="mailbox folders">
    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
    <Hidden mdUp implementation="css">
      <Drawer
        variant="temporary"
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {drawer}
      </Drawer>
    </Hidden>
    <Hidden mdDown implementation="css">
      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        variant="permanent"
        open
      >
        {drawer}
      </Drawer>
    </Hidden>
  </nav>
}

export default NavDrawer
