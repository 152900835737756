import React, { useState, useEffect, useContext, useRef } from 'react'
import MaterialTable from "material-table";

import { EditRounded } from '@material-ui/icons';
import { Switch, Button } from '@material-ui/core';

import { User } from 'src/types';
import tableIcons from 'src/table_icons'
import fetchP from 'src/fetchP';

import AdminPermissionDialog from './admin_permission_dialog';
import SignupDataDialog from './signup_data_dialog';

import { Map } from 'immutable';
import { UserContext } from 'src/UserContext';
import { AdminPermission } from 'src/enums';
import { ActivityModal, ActivityAction } from 'src/components/activity/modal';
import { UserType } from 'src/types/user';

export default function Users() {
  const current_user = useContext(UserContext)
  const [users, setUsers] = useState<Map<string, User>>(Map<string, User>())

  const [loading, setLoading] = useState(true)

  const [adminPermUser, setAdminPermUser] = useState<User>()
  const [signupUser, setSignupUser] = useState<User>()

  const activity_modal = useRef()

  if (!current_user.hasAdminPermission(AdminPermission.USERS)) {
    throw new Error("Insufficient permissions")
  }

  useEffect(() => {
    setLoading(true)
    fetchP({
      url: '/api/users/users',
      success: (json: any) => {
        setUsers(Map(json.users.map((u: any) => [u['email'], new User(u)])))
        setLoading(false)
      }
    })
  }, [])

  if (loading) {
    return <h1>Loading Users...</h1>
  }

  function updateUser(resp: any) {
    if (resp.hasOwnProperty('success') && !resp['success']) {
      return alert(`Error: ${resp['reason']}`)
    }

    const updated_user = new User(resp['user'])
    setUsers(users.set(updated_user.email, updated_user))
  }

  const onLockedChange = (row: User) => (event: React.ChangeEvent<HTMLInputElement>) => {
    fetchP({
      url: '/api/users/set_locked',
      method: 'POST',
      body_obj: {
        email: row.email,
        locked: event.target.checked,
      },
      success: updateUser
    })
  }

  const onAdminChange = (row: User) => (event: React.ChangeEvent<HTMLInputElement>) => {
    fetchP({
      url: '/api/users/set_admin',
      method: 'POST',
      body_obj: {
        email: row.email,
        admin: event.target.checked,
      },
      success: updateUser
    })
  }

  const onPermClick = (event: any, row: any) => {
    setAdminPermUser(row)
  }

  function onPermDialogClose() {
    setAdminPermUser(undefined)
  }

  function onPermSave(resp: any) {
    updateUser(resp)
    setAdminPermUser(undefined)
  }

  function onSignupDataClick(user: User) {
    setSignupUser(user)
  }

  const has_user_change_perm = current_user.hasAdminPermission(AdminPermission.USERS_CHANGE_SETTINGS)

  return <>
    <MaterialTable
      title="Users"
      icons={tableIcons}
      columns={[
        { title: "Name", field: "info.name" as any },
        { title: "Email", field: "email" },
        { title: "Type", field: "user_type", lookup: UserType },
        { title: "Joined", field: "created", type: "datetime", filtering: false },
        {
          title: "", render: row =>
            <Button onClick={() => onSignupDataClick(row)}>Signup Data</Button>
        },
        { title: 'Locked', render: row => <Switch checked={row.locked} onChange={onLockedChange(row)} /> },
        ...(has_user_change_perm ? [
          {
            title: 'Admin', render: (row: User) =>
              <Switch checked={row.admin} onChange={onAdminChange(row)} />
          }
        ] : [])
      ]}
      actions={[
        ActivityAction(activity_modal),
        ...(has_user_change_perm ? [
          {
            icon: () => <EditRounded />, tooltip: 'Edit Permissions', onClick: onPermClick
          }
        ] : [])
      ]}
      data={users.valueSeq().toArray()}
      options={{
        pageSize: 20,
        grouping: true,
        filtering: true
      }}
    />
    {adminPermUser && <AdminPermissionDialog user={adminPermUser} onClose={onPermDialogClose} onSave={onPermSave} />}
    {signupUser && <SignupDataDialog user={signupUser} onClose={() => setSignupUser(undefined)} />}
    <ActivityModal ref={activity_modal} />
  </>
}