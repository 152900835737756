import React from 'react'
import { Record } from 'immutable'

import moment from 'moment'

import { Tooltip } from '@material-ui/core'
import { Link, } from "react-router-dom";


export default class Activity extends Record({
  key: ``,
  user_displayname: ``,
  log: ``,
  created: ``,
  keychain: new Array<Array<string>>([])
}) {
  get created_date() {
    return Date.parse(this.created as any)
  }

  get disp() {
    return <><strong>{this.user_displayname}: </strong>{this.log}</ >
  }

  get time_disp() {
    const m = moment(this.created_date)
    return <Tooltip title={m.format('lll')} placement="top" >
      <span style={{ whiteSpace: 'nowrap' }}> {m.fromNow()} </span>
    </Tooltip>
  }

  get keychain_disp() {
    return this.keychain.map(pair => <Link key={pair[0]} to={`/ACTIVITY/${pair[1]}`}> {pair[0]} </Link>)
  }


}