import React from 'react'

import { Review } from 'src/types/invoice'
import { ThumbUp, ThumbDown, StarBorder, Star, ThumbsUpDown } from '@material-ui/icons'


interface ReviewLabelProps {
  review: Review
}

export default function ReviewLabel({ review }: ReviewLabelProps) {
  if (!review) return <span><ThumbsUpDown fontSize="inherit" /></span>
  return <span>
    {review.work_again === true && <ThumbUp fontSize="inherit" />}
    {review.work_again === false && <ThumbDown fontSize="inherit" />}
    {[0, 1, 2, 3, 4].map(v => {
      return (review.quality > v) ? <Star fontSize="inherit" key={v} /> : <StarBorder fontSize="inherit" key={v} />
    })}
  </span>
}

