import React, { useState, useRef } from 'react'
import { Assignment, AssignmentType } from 'src/types/assignment'
import { OrderedMap } from 'immutable'
import MaterialTable, { Query } from 'material-table'
import fetchP from 'src/fetchP'
import tableIcons from 'src/table_icons'

import { ActivityModal, ActivityAction } from 'src/components/activity/modal'

import moment from 'moment'
import { AssignmentState, Routes } from 'src/types'
import { useLocation, NavLink, useHistory } from 'react-router-dom'
import { Edit, CancelPresentation, Clear, AssignmentTwoTone, Contacts } from '@material-ui/icons'
import { main_service } from 'src/svc'

export default function Assignments() {
  const [loading, setLoading] = useState(true)
  const [selectionMode, setSelectionMode] = useState(false)
  const cursor = useRef(``)
  const count = useRef(0)
  const profile = useRef('')
  const project = useRef('')

  const tableRef = useRef()
  const activity_modal = useRef()

  const location = useLocation()
  const history = useHistory()

  const locationKey = useRef('')


  if (locationKey.current !== location.key) {
    locationKey.current = location.key || ''
    const spl = location.pathname.split('/')
    let fetch_data = false
    switch (spl[2]) {
      case "profile":
        if (profile.current !== spl[3]) {
          fetch_data = true
          profile.current = spl[3]
          project.current = ''
        }
        break;
      case "project":
        if (project.current !== spl[3]) {
          fetch_data = true
          profile.current = ''
          project.current = spl[3]
        }
        break;
      default:
        if (project.current || profile.current) {
          profile.current = ''
          project.current = ''
          fetch_data = true
        }
    }

    if (fetch_data) {
      cursor.current = ''
      count.current = 0
      tableRef.current && (tableRef.current as any).onQueryChange({ page: 0 })
    }
  }

  function onQueryChange(query: Query<Assignment>) {

    return new Promise(resolve => {
      var url: string
      if (profile.current) {
        url = `/api/clients/assignments/profile/${profile.current}`
      }
      else if (project.current) {
        url = `/api/clients/assignments/project/${project.current}`
      } else {
        url = '/api/clients/assignments'
      }

      if (query && query.page) {
        url += `/p/${query.page}/c/${cursor.current}`
      }

      setLoading(true)
      fetchP({
        url,
        success: (json: any) => {
          const assgs_map = OrderedMap<string, Assignment>(json.assignments.map((a: any) => [a['key'], new Assignment(a)]))
          if (json.count) count.current = json.count
          cursor.current = json.cursor

          resolve({
            data: assgs_map.valueSeq().toArray(),
            page: query.page,
            totalCount: count.current,
            pageSize: query
          })
          setLoading(false)
        }
      })
    })
  }

  function cancelAssignments(event: any, rows: Assignment[]) {
    if (window.confirm(`Are you sure you want to cancel ${rows.length} assignments?`)) {
      fetchP({
        url: '/api/clients/force_cancel_assignments',
        method: 'POST',
        body_obj: {
          assignment_keys: rows.map(a => a.key)
        },
        success: (json: any) => {
          if (!json['success']) {
            alert("Cancel Assignment failed!")
          } else {
            tableRef.current && (tableRef.current as any).onQueryChange({ page: 0 })
          }
        }
      })
    }
  }

  return <>
    <MaterialTable
      tableRef={tableRef}
      title={`${loading ? 'Loading Assignments...' : ''}Assignments`}
      icons={tableIcons}
      data={onQueryChange as any}
      columns={[
        {
          title: 'Updated', field: 'updated_date', type: 'datetime', grouping: false, filtering: false,
          render: row => moment(row.updated_date).fromNow(),
        },
        // { title: 'Created', field: 'created_date', type: 'datetime', render: row => moment(row.created_date).fromNow(), grouping: false, filtering: false },
        {
          title: 'Project', field: 'project_title',
          render: row => <NavLink to={`/${Routes[Routes.ASSIGNMENTS]}/project/${(row as any).project_key}`}>{row.project_title}</NavLink>
        },
        {
          title: 'Provider', field: 'profile_name',
          render: row => <NavLink to={`/${Routes[Routes.ASSIGNMENTS]}/profile/${(row as any).profile_key}`}>{row.profile_name}</NavLink>
        },
        { title: 'State', field: 'state', lookup: AssignmentState },
        { title: 'Type', field: 'assignment_type', lookup: AssignmentType },
        { title: 'Client', field: 'creator_name' },
        { title: 'Document', field: 'document_name' },
        { title: 'Breakdown', field: 'cost.breakdown' },
        { title: 'Cost', field: 'cost.as_string' }
      ]}
      options={{
        search: false,
        pageSize: 20,
        pageSizeOptions: [20],
        selection: selectionMode,
        selectionProps: (row: Assignment) => ({
          disabled: row.state >= AssignmentState.COMPLETED,
          color: 'primary'
        })

      }}
      actions={[
        ActivityAction(activity_modal),
        ...(profile.current || project.current ? [
          {
            tooltip: 'Clear Project Filter',
            icon: () => <><Clear /> Clear Filter</>,
            isFreeAction: true,
            onClick: () => history.push(`/${Routes[Routes.ASSIGNMENTS]}`),
          }
        ] : []),
        {
          isFreeAction: true,
          icon: () => <Edit color={selectionMode ? 'secondary' : undefined} />,
          tooltip: 'Edit Assignments',
          onClick: () => setSelectionMode(!selectionMode),
        },
        ...(selectionMode ? [{
          tooltip: 'Cancel Assignments',
          icon: () => <CancelPresentation />,
          onClick: cancelAssignments as any
        }] : [])
      ]}
    />

    <ActivityModal ref={activity_modal} />
  </>
}

export function AssignmentAction(key_type: "project" | "profile", row_to_key: any) {
  return {
    icon: () => <AssignmentTwoTone />,
    tooltip: 'Assignments',
    onClick: (event: any, row: any) => {
      window.open(`/${Routes[Routes.ASSIGNMENTS]}/${key_type}/${row_to_key(row)}`)
    }
  }
}

export function ProfileView(row_to_key: any) {
  return {
    icon: () => <Contacts />,
    tooltip: 'View Profile',
    onClick: (event: any, row: any) => {
      window.open(`${main_service()}/p/${row_to_key(row)}`)
    }
  }
}