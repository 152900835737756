import { Record } from "immutable"
import moment from "moment"
import { Project } from "./project"

export enum InvoiceState {
  REQUEST = 1,
  PAID = 5,
  REJECTED = 8,
  CANCELLED = 9
}

class ProviderPayments {
  static BankAccountDetails = class {
    BANK = ``
    BRANCH = ``
    CITY = ``
    STATE = ``
    IFSC = ``
  }
  name = ``
  address = ``
  bank_account = ``
  bank_account_holder_name = ``
  bank_account_type = ``
  bank_ifsc_details = new ProviderPayments.BankAccountDetails()

}

export enum ReviewType {
  CLIENT = 1,
  PROVIDER = 2
}

export class Review extends Record({
  created: ``,
  review_by: ``,
  review_of: ``,
  comments: ``,
  comments_fs: ``,
  invoice_size: 0,
  quality: 0,
  review_type: ReviewType.CLIENT,
  work_again: undefined
}) {

}

export class Invoice extends Record({
  uid: ``,
  key: ``,
  created: ``,
  updated: ``,
  state: InvoiceState.REQUEST,
  project: new Project({}),
  provider_payments: new ProviderPayments(),
  adjustments: [],
  service_rate_override: null,
  work: 0,
  total: 0,
  pdf_c: ``,
  pdf_s: ``,
  review_client: new Review(),
  review_provider: new Review()
}) {
  constructor(jsObj: any) {
    super({
      ...jsObj,
      project: new Project(jsObj.project),
      review_client: new Review(jsObj.review_client),
      review_provider: new Review(jsObj.review_provider),

    })
  }
  get gst_month() {
    return moment(Date.parse(this.created as any)).format('MMM-YY')
  }

  get created_date() {
    return Date.parse(this.created as any)
  }

  get updated_date() {
    return Date.parse(this.updated as any)
  }

  equals(other: Invoice) {
    return this.uid === other.uid
  }

  get_review(review_type: ReviewType) {
    switch (review_type) {
      case ReviewType.CLIENT: return this.review_client;
      case ReviewType.PROVIDER: return this.review_provider
    }
  }
}

