interface fetchPInterface {
  url: string,
  body_obj?: any,
  credentials?: string,
  method?: string,
  options?: any,
  success?: any
}

const fetchP = function ({
  url,
  body_obj,
  credentials = 'include',
  method = 'GET',
  options = {},
  success
}: fetchPInterface) {
  const opts = Object.assign(
    {
      credentials,
      method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body_obj),
    },
    options
  );

  if (opts.headers === 'remove') {
    delete opts.headers;
  }

  const fetchPromise = fetch(url, opts)
    .then(response => {
      if (!response.ok) {
        return {
          success: false,
          reason: response.statusText
        }
      }
      return response.json()
    }).then(success)


  return fetchPromise;
}

export function postFile(url: string, file: any) {
  const data = new FormData();
  if (file)
    data.append('data', file);

  return fetchP({
    url,
    method: 'POST',
    options: {
      body: data,
      headers: 'remove'
    },
  });
}

export default fetchP;