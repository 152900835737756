import React, { useState } from 'react'

import {
  Grid, makeStyles, Theme, createStyles, FormControl, FormGroup, FormControlLabel, Switch,
  Button,
  ButtonGroup
} from '@material-ui/core';
import { Archive, Unarchive, Check } from '@material-ui/icons';
import fetchP from 'src/fetchP';
import { InternalSettingTypes } from 'src/enums';
import { ProviderUserWithSignupData } from './providers';
import { ClientUserWithSignupData } from './clients';


interface IProps {
  user: ProviderUserWithSignupData & ClientUserWithSignupData
  updateUser: Function
}

export function ActionsGridItem(props: IProps) {
  const classes = useStyles();
  const { user, updateUser } = props
  const { active } = user
  const [loading, setLoading] = useState(false)


  const archived = user.getInternalSettingValue(InternalSettingTypes.OnboardingArchived)
  const contacted = user.getInternalSettingValue(InternalSettingTypes.OnboardingContacted)

  function updateSuccess(json: any) {
    if (!json['success']) {
      alert("Action failed!")
    } else {
      updateUser(json['user'])
    }
    setLoading(false)
  }

  function toggleArchive() {
    if (window.confirm(`Are you sure you want to ${archived ? 'un' : ''}archive ${props.user.info.name}?`)) {
      setLoading(true)
      fetchP({
        url: '/api/onboarding/set_archive',
        method: 'POST',
        body_obj: {
          user_key: user.key,
          value: !archived
        },
        success: updateSuccess
      })
    }
  }

  function toggleContacted() {
    setLoading(true)
    fetchP({
      url: '/api/onboarding/set_contacted',
      method: 'POST',
      body_obj: {
        user_key: user.key,
        value: !contacted
      },
      success: updateSuccess
    })
  }

  function activate() {
    if (window.confirm(`Are you sure you want to ACTIVATE ${props.user.info.name}?`)) {
      fetchP({
        url: '/api/onboarding/activate',
        method: 'POST',
        body_obj: {
          user_key: user.key
        },
        success: updateSuccess
      })
    }
  }

  return <Grid item className={classes.actionsGridItem}>
    <FormControl component="fieldset">
      <FormGroup aria-label="position" row>
        <FormControlLabel
          value="top"
          control={<Switch checked={contacted} onChange={toggleContacted} disabled={loading} size="small" color="primary" />}
          label="Contacted"
        />
        <ButtonGroup>
          <Button
            disabled={loading || active}
            onClick={toggleArchive}
            size="small" color="secondary" variant="outlined">
            {archived ? <><Unarchive /> Unarchive</> : <><Archive /> Archive</>}
          </Button>
          <Button
            disabled={loading || active}
            onClick={activate}
            size="small" color="primary" variant="outlined">
            <Check /> Activate
        </Button>
        </ButtonGroup>
      </FormGroup>
    </FormControl>
  </Grid>
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionsGridItem: {
      width: '100%',
      textAlign: 'center',
      padding: theme.spacing(1)
    }
  })
);