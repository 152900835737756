import React from 'react'
import Markdown from 'markdown-to-jsx';

export default function EmailContents({ title, contents, content_el }) {
  return <div style={{ backgroundColor: '#f5f5f5' }}>

    <table border="0" cellPadding="0" cellSpacing="0" width="100%">

      <tbody>
        <tr>
          <td bgcolor="#00aeef" align="center" width="600">

            <table border="0" cellPadding="0" cellSpacing="0" width="100%" style={{ maxWidth: '600px' }}>
              <tbody><tr>
                <td align="center" valign="top" style={{ padding: '40px 10px 15px 10px' }}>
                  <a href="https://fieldscope.in" style={{ textDecoration: 'none' }}>
                    <img alt="Fieldscope"
                      src="https://storage.googleapis.com/fieldscope-pub/email/v1/logo-hor-white.png"
                      width="462" height="75"
                      style={{
                        display: 'block', width: '462px', maxWidth: '462px', minWidth: '462px', fontFamily: 'Helvetica, Arial, sans-serif', color: '#ffffff', fontSize: '75px'
                      }} border="0" />
                  </a>
                </td>
              </tr>
              </tbody></table>


          </td>
        </tr>

        <tr>
          <td bgcolor="#00aeef" align="center" style={{ padding: "0px 10px 0px 10px" }}>

            <table border="0" cellPadding="0" cellSpacing="0" width="100%" style={{ maxWidth: '600px' }}>
              <tbody><tr>
                <td bgcolor="#ffffff" align="center" valign="top" style={{ padding: '40px 20px 20px 20px', borderRadius: '4px 4px 0px 0px', color: '#111111', letterSpacing: '4px', lineHeight: '48px' }}>
                  <h1 style={{ fontSize: '48px', fontWeight: '600', margin: '0' }}>{title}</h1>
                </td>
              </tr>
              </tbody></table>
          </td>
        </tr>


        <tr>
          <td bgcolor="#f5f5f5" align="center" style={{ padding: '0px 10px 0px 10px' }}>
            <table border="0" cellPadding="0" cellSpacing="0" width="100%" style={{ maxWidth: '600px' }}>
              <tbody>
                <tr>
                  <td ref={content_el} bgcolor="#ffffff" style={{ padding: '20px 0px 0px 20px', color: '#666666', fontSize: '18px', fontWeight: '400', lineHeight: '25px' }}>
                    <Markdown>{contents}</Markdown>
                  </td>
                </tr>
              </tbody></table>
          </td>
        </tr>


        <tr>
          <td bgcolor="#f5f5f5" align="center" style={{ padding: '30px 10px 0px 10px' }}>


            <table border="0" cellPadding="0" cellSpacing="0" width="100%" >

              <tbody><tr>

                <td bgcolor="#ffd699" align="center" style={{ padding: '30px 30px 30px 30px', borderRadius: '4px 4px 4px 4px', color: '#666666', fontSize: '18px', fontWeight: '400', lineHeight: '25px' }}>
                  <h2 style={{ fontSize: '20px', fontWeight: 400, color: '#111111', margin: 0 }}>Have a question?
                          </h2>
                  <p style={{ margin: 0 }}>
                    <a href="mailto:support@fieldscope.in" style={{ color: '#2196f3' }}>We’re here, ready to talk</a>
                  </p>
                </td>

              </tr>
              </tbody></table>


          </td>
        </tr>

        <tr>
          <td bgcolor="#f5f5f5" align="center" style={{ padding: '0px 10px 0px 10px' }}>

            <table border="0" cellPadding="0" cellSpacing="0" width="100%" style={{ maxWidth: '600px' }}>

              <tbody><tr>
                <td bgcolor="#f5f5f5" align="center" style={{ padding: '0px 30px 15px 30px', color: '#666666', fontSize: '14px', fontWeight: 400, lineHeight: '18px' }}>
                  <p style={{ margin: 0 }}>If these emails get annoying, please feel free to
                              <a href="https://fieldscope.in/app/settings/email" style={{ color: '#111111' }}> unsubscribe</a>.</p>
                </td>
              </tr>

                <tr>
                  <td align="center">
                    <a href="https://fieldscope.in" >
                      <img alt="Fieldscope"
                        src="https://storage.googleapis.com/fieldscope-pub/email/v1/mark-cyan.png"
                        width="50" height="50"
                        style={{ display: 'block', width: '50px', maxWidth: '50px', minWidth: '50px', color: '#444444', fontSize: '18px' }}
                        border="0" />
                    </a>
                  </td>
                </tr>
              </tbody></table>

          </td>
        </tr>
      </tbody></table>



  </div>
}