import React, { useState, useContext } from 'react';

import { AppBar, Typography, Toolbar, makeStyles, IconButton, createStyles, Theme, Button } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import NavDrawer from './NavDrawer';
import logo_inverse from './logo-heavy-inverse.png';
import { UserContext } from 'src/UserContext';

import { NavLink, useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: 1201
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
    logo: {
      width: "154px",
      height: "18px",
      marginBottom: '-0.10rem'
    },
    grow: {
      flexGrow: 1
    }
  }),
);


function TopBar(props: any) {
  const { onLogout, drawerWidth } = props
  const user = useContext(UserContext)
  const classes = useStyles({ drawerWidth });
  const [mobileOpen, setMobileOpen] = useState(false);

  const location = useLocation();
  const history = useHistory();

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  function logout() {
    fetch('/api/auth/logout.json').then(resp => {
      onLogout();
      history.push('/');
    })
  }

  const sub_page = location.pathname.toLocaleUpperCase().split('/')[1]
  const { logged_in } = user

  return <><AppBar position="fixed" className={classes.appBar}>
    <Toolbar>
      {logged_in &&
        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu"
          onClick={() => setMobileOpen(!mobileOpen)}
        >
          <MenuIcon />
        </IconButton>
      }

      <Typography className={classes.grow} variant="h6" noWrap>
        <NavLink className="clean-link" to="/">
          Admin | <img
            className={classes.logo}
            alt="Fieldscope Admin"
            src={logo_inverse}
          /></NavLink>
        {sub_page && ` / ${sub_page.split('_').join(' ')}`}
      </Typography>
      {logged_in &&
        <>
          {user.info.name}
          <Button onClick={logout} color="inherit">Logout</Button>
        </>
      }

    </Toolbar>

  </AppBar>
    {logged_in && <NavDrawer
      handleDrawerToggle={handleDrawerToggle}
      mobileOpen={mobileOpen}
    />}
  </>
}

export default TopBar