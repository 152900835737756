import React, { useState, useEffect } from 'react'
import { Map } from 'immutable'
import { ReviewType, Invoice } from 'src/types/invoice'
import tableIcons from 'src/table_icons'
import MaterialTable from 'material-table'
import moment from 'moment'
import fetchP from 'src/fetchP'
import { Button } from '@material-ui/core'
import ReviewLabel from 'src/components/review/label'
import ReviewModalEdit from './modal'

interface modalData {
  invoice: Invoice,
  review_type: ReviewType
}

export default function () {
  const [invoices, setInvoices] = useState<Map<string, Invoice>>(Map<string, Invoice>())
  const [loading, setLoading] = useState(true)

  const [modalData, setModalData] = useState<modalData>()

  function onReviewUpdate(invoice: Invoice) {
    setInvoices(invoices.set(invoice.uid, invoice))
  }

  useEffect(() => {
    setLoading(true)
    fetchP({
      url: '/api/reviews/',
      success: (json: any) => {
        setInvoices(Map(json.invoices.map((i: any) => [i['uid'], new Invoice(i)])))
        setLoading(false)
      }
    })
  }, [])


  function show_review(review_type: ReviewType) {
    return function (invoice: Invoice) {
      const review = invoice.get_review(review_type)
      return <Button onClick={() => setModalData({ invoice, review_type })}>
        <ReviewLabel review={review} /> <span style={{ marginLeft: '0.5em' }}>₹{review.invoice_size / 100}</span>
      </Button>
    }
  }

  return <>
    <MaterialTable
      title={`${loading ? 'Loading ' : ''}Reviews`}
      icons={tableIcons}
      data={invoices.valueSeq().toArray()}
      columns={[
        { title: 'Updated', field: 'updated_date', type: 'datetime', render: row => moment(row.updated_date).fromNow(), grouping: false, filtering: false },
        { title: 'Created', field: 'created', type: 'datetime', render: row => moment(row.created).fromNow(), grouping: false, filtering: false },
        { title: 'Project', field: 'project.title' as any },
        { title: 'Client', field: 'project.owner' as any },
        { title: 'Provider', field: 'provider_payments.name' as any },
        { title: 'ID#', field: 'uid', grouping: false, filtering: false },
        { title: 'Client', field: 'review_client', render: show_review(ReviewType.CLIENT) },
        { title: 'Provider', field: 'review_provider', render: show_review(ReviewType.PROVIDER) },
      ]}
    />
    {modalData &&
      <ReviewModalEdit
        invoice={modalData.invoice} review_type={modalData.review_type}
        onClose={() => setModalData(undefined)}
        onSave={onReviewUpdate} />
    }
  </>

}