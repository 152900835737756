import React, { useContext } from 'react'

import {
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import { Routes } from './types';
import { UserContext } from './UserContext';
import Users from './pages/users';
import Providers from './pages/providers';
import Projects from './pages/clients/projects';
import Assignments from './pages/clients/assignments';
import ClientSignups from './pages/onboarding/clients';
import ProviderSignups from './pages/onboarding/providers';
import Invoices from './pages/finance/invoices';
import Activities from './pages/activity';
import Reviews from './pages/reviews';
import Email from './pages/email';
import Certs from './pages/certs';

const RoutePageMap = {
  [Routes.ACTIVITY]: Activities,
  [Routes.USERS]: Users,
  [Routes.EMAIL]: Email,
  [Routes.CERTS]: Certs,
  [Routes.PROVIDERS]: Providers,
  [Routes.PROJECTS]: Projects,
  [Routes.ASSIGNMENTS]: Assignments,
  [Routes.ONBOARDING_CLIENTS]: ClientSignups,
  [Routes.ONBOARDING_PROVIDERS]: ProviderSignups,
  [Routes.INVOICES]: Invoices,
  [Routes.REVIEWS]: Reviews,
}

export default function AdminRoutes() {
  const user = useContext(UserContext)
  return <Switch>
    {user.routes.map((r: Routes) =>
      <Route key={r} path={`/${Routes[r]}`} component={RoutePageMap[r]} />
    )}
    <Route exact path='/' render={() => <Redirect to={`/${Routes[user.routes[0]]}`} />} />

  </Switch>
}