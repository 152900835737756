import { InternalSettingTypes, InternalSettingGroup, UserSettingGroup, UserSettingTypes } from "src/enums";
import { User } from "./user";

export { User }

export enum Routes {
  ACTIVITY, USERS, EMAIL, CERTS,
  PROJECTS, ASSIGNMENTS,
  PROVIDERS,
  ONBOARDING_CLIENTS, ONBOARDING_PROVIDERS,
  INVOICES,
  REVIEWS
}

export interface InternalSetting {
  description: string,
  type: InternalSettingTypes,
  group: InternalSettingGroup,
  value: any
}

export interface UserSetting {
  description: string,
  type: UserSettingTypes,
  group: UserSettingGroup,
  value: any
}

export enum AssignmentState {
  ASSIGNED = 0,
  IN_PROGRESS = 1,
  REVIEW = 2,
  COMPLETED = 5,
  CANCELLED = 8,
  REJECTED = 9
}