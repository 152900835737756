import React, { useState, useEffect } from 'react';

import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import TopBar from './TopBar'
import { UserContext } from './UserContext'
import AdminRoutes from './AdminRoutes'

import Login from './Login'
import { User } from './types/user';
import { set_langs } from './types/lang';
import { RECAPTCHA_SITE_KEY } from './config';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    toolbar: {
      ...theme.mixins.toolbar,
      marginTop: -theme.spacing(1),
    },
    login_paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }))


const App: React.FC = () => {
  const [user, setUser] = useState<User>()
  const [checkedAuth, setCheckedAuth] = useState<boolean>(false)
  
  const classes = useStyles()

  function updateUser(user?: any) {
    setCheckedAuth(true)
    if (!user) return setUser(undefined)

    if (user && (!user['admin'] && !user['app_admin'])) {
      alert("You're not an admin!")
      fetch('/api/auth/logout.json').then(onLogout)
      return setUser(undefined)
    }
    user['logged_in'] = true
    setUser(new User(user))
  }

  useEffect(() => {
    fetch('/api/users/status')
      .then(res => res.json())
      .then(body => {
        if (body.success) {
          const { user } = body
          updateUser(user)
        } else {
          updateUser(false)
        }
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (!checkedAuth) {
    return <h1>Loading...</h1>
  }

  function onLogout() {
    updateUser(false)
  }

  return <div className={classes.root}>
    <CssBaseline />
    <UserContext.Provider value={user || new User()}>
      <BrowserRouter>
        <TopBar {...{ onLogout }} />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {(user && user.logged_in) ?
            <AdminRoutes />
            :
            <Switch>
              <Route path="/">
                <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
                  <Login setUser={updateUser} />
                </GoogleReCaptchaProvider>
              </Route>
            </Switch>
          }
        </main>
      </BrowserRouter>
    </UserContext.Provider>
  </div >
}

set_langs()

export default App;
